import i18n from '@/i18n';

export default {
	colors: ['#174a7c', '#6b95c3', '#0e2c49', '#2578c8', '#476d92'],
	dataLabels: {
		style: {
			fontSize: '14px',
			fontFamily: 'Abel',
		},
	},
	tooltip: {
		y: {
			formatter: (value) => `${Math.round(value * 100) / 100} kgCO₂`,
			title: { formatter: (name) => i18n.t(`report.group.${name}`) },
		},
	},
	legend: {
		position: 'bottom',
		fontSize: '16px',
		fontFamily: 'Abel',
		formatter: (name) => i18n.t(`report.group.${name}`),
		onItemClick: { toggleDataSeries: true },
		onItemHover: { highlightDataSeries: true },
	},
	plotOptions: {
		pie: {
			expandOnClick: false,
			donut: { size: '60%' },
		},
	},
};
