<template lang="html">
	<article class="step">
		<h2>{{ $t('global.heading.results') }}</h2>
		<section class="summary">
			<p class="summary__total">{{ total | toTones }}<em class="unit">tCO₂</em></p>
			<ul class="summary__resume">
				<li v-for="step in summary" :key="step.name">
					<h5>
						{{ $t(`${sector}.${step.name}.title`) }}
						<strong>{{ step.total | toTones }}<em class="unit">tCO₂</em></strong>
					</h5>
					<ul class="summary__details">
						<li v-for="group in step.emissions" :key="group.name">
							{{ $t(`${sector}.${step.name}.${group.name}.title`)  }}
							<strong>{{ group.total }}<em class="unit">kgCO₂</em></strong>
						</li>
					</ul>
				</li>
			</ul>
		</section>
		<section v-if="total" class="chart">
			<apex-chart
				width="380"
				type="donut"
				:options="chartOptions"
				:series="Object.values(groupedEmissions)" />
		</section>
		<section class="comparative" v-if="total && report.type === 'DOM'">
			<h3>Les emissions equivalen a...</h3>
			<div class="grid">
				<div
					v-for="item in comparativeEmissions"
					:key="item.name"
					class="grid__col grid__col--4">
					<p class="note">
						{{ item.count }} {{ $t(`report.comparative.${item.name}`) }}
					</p>
					<p class="icons">
						<i v-for="i in item.count" :key="i" :class="`mdi mdi-${item.icon}`" />
					</p>
				</div>
			</div>
		</section>
		<section class="details">
			<h4>{{ $t(`${sector}.details.title`) }}</h4>
			<p v-for="(value, detail) in report.details" :key="detail" class="detail">
				<em>{{ $t(`${sector}.details.placeholder.${detail}`) }}</em>
				{{ value | serialize }}
			</p>
			<div v-if="report.editable" class="save">
				<p v-if="!digicert" class="note">{{ $t('global.tip.login_to_save') }}</p>
				<p>
					<button
						@click="report.id ? updateReport() : saveReport()"
						:disabled="!digicert"
						class="btn">
						{{ $t(`global.button.${report.id ? 'update' : 'save'}`) }}
					</button>
				</p>
			</div>
		</section>
	</article>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import ApexChart from 'vue-apexcharts';
import donutChartOptions from '@/styles/charts/donut';
import api from '@/api';
import { toTones, serialize } from '@/utils';
import StepMixin from '@/mixins/StepMixin';

const reduceEmissions = (obj) => Object.entries(obj).map(([name, items]) => {
	if (!items) return { name, total: 0 };
	const emissions = Array.isArray(items) ? undefined : reduceEmissions(items);
	const total = emissions
		? emissions.reduce((acc, { total: t }) => acc + t, 0)
		: items.reduce((acc, { emissions: e }) => acc + e, 0);
	return { name, emissions, total: Math.round(total * 100) / 100 };
});

const groupName = (name) => {
	switch (name) {
		case 'fixes': case 'fuels': case 'energy': return 'energy';
		case 'mobils': case 'workers': case 'spectators': case 'external': return 'transport';
		case 'products': case 'materials': case 'livestock': return 'products';
		default: return name;
	}
};

const comparativesCO2 = [
	{ name: 'flightEarth', emissions: 7223.92, icon: 'airplane' },
	{ name: 'cow', emissions: 2212, icon: 'cow' },
	{ name: 'car', emissions: 2683, icon: 'car-side' },
];

export default {
	name: 'ResultsView',
	mixins: [StepMixin],
	components: { ApexChart },
	filters: { toTones, serialize },
	computed: {
		...mapState(['digicert', 'report']),
		summary() {
			return reduceEmissions(this.report.emissions)
				.sort((a, b) => a.name.localeCompare(b.name));
		},
		groupedEmissions() {
			return this.summary.reduce((acc, { emissions = [] }) => {
				emissions.forEach(({ name, total }) => {
					const group = groupName(name);
					acc[group] = acc[group] + total || total;
				});
				return acc;
			}, {});
		},
		comparativeEmissions() {
			return comparativesCO2.map((item) => ({
				name: item.name,
				count: Math.round(this.total / item.emissions),
				icon: item.icon,
			}));
		},
		chartOptions() {
			return { ...donutChartOptions, labels: Object.keys(this.groupedEmissions) };
		},
		total() { return this.summary.reduce((acc, { total }) => acc + total, 0); },
	},
	methods: {
		...mapMutations(['setReport']),
		saveReport() {
			const { type, details, emissions } = this.report;
			const { name } = details;
			api.post('/reports', { type, name, content: { details, emissions } })
				.then(({ data }) => {
					this.setReport({ editable: true, id: data.id, type, details, emissions });
					this.flashMessage('success', this.$t('feedback.success.report_save'));
				}).catch(({ response: { data } }) => this.flashMessage('error', data));
		},
		updateReport() {
			const { id, details, emissions } = this.report;
			const { name } = details;
			api.put(`/reports/${id}`, { name, content: { details, emissions } })
				.then(() => this.flashMessage('success', this.$t('feedback.success.report_update')))
				.catch(({ response: { data } }) => this.flashMessage('error', data));
		},
	},
	mounted() { this.$emit('validate', true); },
};
</script>
